<template>
    <div style="background-color:#EFF1F2;height:100%;width:100%">
      <div style="padding:20px;padding-top:4% !important;">
        <v-img src="../assets/gimbook_logo.svg" style="max-width:200px; max-height:300px;" class="mx-auto"></v-img>
        <v-card
          class="mx-auto  mt-5"
          style="padding:60px;min-width:250px;margin-top:6% !important;"
          max-width="600"
          max-height="400"
        >
        <span v-if="this.$store.state.Auth.access">{{goToHome()}}</span>
        
        <p style="color:red ;margin:5px 10px;font-size:18px;" v-if="this.$store.state.Auth.errorMessage=='No active account found with the given credentials'"> {{this.$store.state.Auth.errorMessage}}</p>
      
          <form @submit="onSubmit" >


            <div class="input-icons"> 
                <i>
                  <v-icon class="icon" large>mdi-account</v-icon>
                </i> 
                <input 
                  class="input-field" 
                  type="text" 
                  v-model="phone"
                  placeholder="Username"
                > 
            </div>
            <div class="input-icons"> 
                <i>
                  <v-icon class="icon" large>mdi-lock</v-icon>
                </i> 
                <input class="input-field" 
                  type="password" 
                  v-model="password"
                  placeholder="Password "
                > 
            </div> 
            
            <div class="my-2">
              <v-btn
                x-large
                dark
                type="submit"
                style="width:100%;margin:auto;background-color:#363740;"
              >
                Sign In
              </v-btn>
            </div>  
                
        </form>
        <v-card-actions >
          <router-link
            text
            class="ml-auto"
            to="/password-reset"
            color="black"
            style="color:black;text-decoration:none;font-size:20px"
          >
            Forgot Password ?
          </router-link>
        </v-card-actions>
      </v-card> 
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    name:"Login",
    data(){
        return {
            phone:'',
            password:'',
            rules: {
              required: value => !!value || 'Required.',
              counter: value => value.length <= 12 || 'Max 12 characters',
              phone: value => {
                const pattern = '/(0/91)?[7-9][0-9]{9}/'
                return pattern.test(value) || 'Invalid Phone Number.'
              },
            },
        
        }
    },
    methods:{
            ...mapActions('Auth',['loginUser']),
            onSubmit(e){
                e.preventDefault();
                console.log(this.phone, this.password, 'in login')
    
                this.loginUser({'phone':this.phone, 'password':this.password});
                this.phone="";
                this.password="";
                this.$store.state.Auth.errorMessage=""
            },
            goToHome() {    
                this.$router.push({name:'Home'})
                
            }   
    },
    mounted(){
      this.$store.state.Auth.access=false;
    }

    }

</script>

<style scoped>

        .input-icons i { 
            position: absolute; 
        } 
          
        .input-icons { 
            width: 100%; 
            margin-bottom: 25px; 
            
        } 
          
        .icon { 
            padding: 8px; 
            color: darkgrey;
            background-color:lightgray; 
            min-width: 20px; 
            border:1px solid darkgrey;
            text-align: center; 
        } 
          
        .input-field { 
          border:1px solid grey;
            width: 100%;
            height:55px; 
            font-size:18px;
            padding-left: 60px; 
            text-align: left; 
        } 
          
        h2 { 
            color: green; 
        } 

</style>