<template>
	 <v-row justify="center">
    <v-dialog
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
      v-model="dialog"
      width="600px"
    >
      <template v-slot:activator="{ on, attrs }" style="min-width:50px !important;">
          
           <v-btn 
	            v-bind="attrs"
		          v-on="on" color="red"
		          @click="open"      
		          dense
              outlined
              style="color:#363740;margin-top:10px;margin-left:0px;min-width:50px !important;border:1px solid #363740;"           
              >
             cancel
            </v-btn>
      </template>
      <v-card>
        <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">Confirm Cancellation</v-toolbar-title>
      </v-toolbar>
        <v-card-text class="pa-4">
            Are you sure you want to cancel this Payout Slip ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#363740 darken-1"
            text
            @click="cancel"
          >
            No
          </v-btn>
          <v-btn
            color="#363740 darken-1"
            text
            @click="agree"
          >
           Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>


import api from "@/helper-axios.js";
import {mapActions} from 'vuex';
  export default {
    name:"CancelPayout",
    props:[
        'id'
    ],
    data () {
      return {
        dialog: false,
        options: {
          color: 'red',
          width: 290,
          zIndex: 200
        },
        resolve:null,
        reject:null,

      }
    },
    methods:{
      ...mapActions("snackbar", ["showSnack"]),
      onRemove(id){

          api.put(`${this.$store.state.Auth.staging_url}/api/payout/cancel/${id}/`)
          .then((res)=>
          {console.log(id);

            this.showSnack({
                text: "Successfully Cancelled",
                color: "success",
                timeout: 4500,
            });

            })
          .catch(err=>console.log(err.response.data))


      },
      open() {
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
        this.onRemove(this.id)

        setTimeout(()=>{
          this.$emit('reloadPayout')
          console.log('emited')

        }, 1000)
        console.log(this.resolve, "This is agree")
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
        console.log(this.resolve, "This is disagree")

      }
    }
  }
</script>


<style  scoped>
 .icons{
  display:inline-block;
  margin:3px;
  padding:3px 3px;
  cursor:pointer;
  margin:2px ;
  background-color:#F2F2F2;
 }
</style>
	
</script>

<style>
	
</style>