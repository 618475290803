<template>
  <div>
    <v-dialog v-model="form_data.flag" max-width="550px">
      <div v-if="loader" class="text-center" style="min-height: 200px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card v-if="!loader">
        <v-card-title
          class="headline darken-1"
          style="background-color: #363740; color: white"
        >
          {{ Heading }} Payout
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            @click.prevent="CloseForm()"
            large
            style="cursor"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-form style="padding: 20px; background-color: #f4f4f4">
          <p>
            Payment Date:
            <strong>
              <span v-if="form_data.type == 'add'">{{
                new Date().toJSON().slice(0, 10).replace(/-/g, "/")
              }}</span>
              <span v-if="form_data.type == 'edit'">{{
                new Date(date).toJSON().slice(0, 10).replace(/-/g, "/")
              }}</span>
            </strong>
          </p>
          <v-row>
            <v-col cols="6">
              <label>Select Teammate</label>
              <v-select
                :items="items"
                outlined
                v-model="team_id"
                item-text="name"
                item-value="id"
                @change="GetTeammateData"
                style="background-color: #fff; height: 40px"
                dense
              >
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.name }}( {{ data.item.Type }} )
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <label>Amount<span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="amount"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
              <strong>Balance: <span style="color: green;font-size:15px;">{{this.teammate_balance}}</span></strong>
            </v-col>
            <v-col cols="12">
              <label>Remark<span style="color: red">*</span></label>
              <v-textarea
                outlined
                style="height: 80px"
                dense
                v-model="remark"
                background-color="white"
                auto-grow
                height="80"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <div style="min-height: 50px; background-color: #f4f4f4"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
            "
            color="#363740"
            large
            v-if="form_data.type == 'add'"
            @click="AddPayout()"
            type="submit"
            :disabled="btnloader"
            :loading="btnloader"
          >
            Add
          </v-btn>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
            "
            color="#363740"
            large
            v-if="form_data.type == 'edit'"
            @click="EditPayout()"
            type="submit"
            :disabled="btnloader"
            :loading="btnloader"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import api from "@/helper-axios.js";
import { mapActions } from "vuex";

export default {
  name: "PayoutForm",
  props: ["form_data"],
  data() {
    return {
      items: [],
      team_id: "",
      remark: "",
      loader: false,
      amount: "",
      date: "",
      btnloader:false,
      teammate_balance: ""
    };
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    CloseForm() {
      this.form_data.flag = false;
    },
    GetTeammateData(){
       api
        .get(
          `${this.$store.state.Auth.staging_url}/api/team/edit/${this.team_id}/`
        )
        .then((res) => {
          this.teammate_balance = res.data.balance  
          console.log(this.team_id);
        })
        .catch((err) => {
          console.log(err);
        });        
    },
    getTeams() {
      api
        .get(
          `${
            this.$store.state.Auth.staging_url
          }/api/team/admin/list/${1}/${null}/`
        )
        .then((res) => {
          this.items = res.data;
          console.log(this.items, "items");
          // console.log( this.managers)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    EditPayout() {
      if (this.team_id && this.remark && this.amount) {
        this.loader = true;
         this.btnloader = true
        const data = {
          team_id: this.team_id,
          remark: this.remark,
          amount: this.amount,
        };
        console.log(data);
        api
          .put(
            `${this.$store.state.Auth.staging_url}/api/payout/edit/${this.form_data.id}/`,
            data
          )
          .then((res) => {
            console.log(res.data);
            this.showSnack({
              text: "Payout is Edited",
              color: "success",
              timeout: 3500,
            });
            this.$emit("reloadPayout");
            setTimeout(() => {
               this.btnloader =false
              this.CloseForm();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.showSnack({
          text: "Please fill all required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },
    AddPayout() {
      if (this.team_id && this.remark && this.amount) {
        this.btnloader = true
        const data = {
          team_id: this.team_id,
          remark: this.remark,
          amount: this.amount,
        };
        console.log(data);
        api
          .post(`${this.$store.state.Auth.staging_url}/api/payout/add/`, data)
          .then((res) => {
            console.log(res.data);
            this.showSnack({
              text: "Payout is Added",
              color: "success",
              timeout: 3500,
            });
            this.$emit("reloadPayout");
            setTimeout(() => {
              this.btnloader = false
              this.CloseForm();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          }).finally(()=>{
            
          })
      } else {
        this.showSnack({
          text: "Please fill al required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },
  },
  computed: {
    Heading() {
      if (this.form_data.type === "edit") {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
  mounted() {
    this.getTeams();
    if (this.form_data.type == "edit") {
      this.loader = true;
      axios
        .get(
          `${this.$store.state.Auth.staging_url}/api/payout/get/${this.form_data.id}/`
        )
        .then((res) => {
          this.amount = res.data.amount;
          this.remark = res.data.remark;
          this.date = res.data.date;

          this.getTeams();
          setTimeout(() => {
            this.team_id = parseInt(res.data.team.id);
          }, 1000);
          // this.team_id=parseInt(res.data.team.id)
          console.log(this.team_id);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loader = false;
        });
    }
  },
};
</script>

<style scope></style>



