<template>
	<div>
		<PayoutTable />
	</div>
</template>

<script>
import PayoutTable from "@/components/Payouts/PayoutTable"
export default {
	name:'Payout',
	components:{
		PayoutTable,
	},
	methods:{},
	mounted(){
		this.$store.state.Auth.access=true;
	}   
}
</script>


<style scoped>
	
</style>