export  const ref_data=[
				{
					"date":'11-2-2012',
					"team":['SDK Rahul', 'Amit Kumar(Partner)'],
					"company_details":['Abhishek Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['1 year', 'Rs 2000'],
					"add_on_plan":['50 e-Waybill', 'Rs 500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'12-2-2012',
					"team":['SDK Abhishek', 'Ayush Jain(Partner)'],
					"company_details":['Jain Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['3 year', 'Rs 8000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'13-2-2012',
					"team":['SDK Akash', 'Kajal Verma(Partner)'],
					"company_details":['Akash Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['2 year', 'Rs 5000'],
					"add_on_plan":['100 e-Waybill', 'Rs 1000'],
					"referral_source":'Advertisement',
				},
				{
					"date":'14-2-2012',
					"team":['SDK Jeevan', 'Rishabh Pant(Partner)'],
					"company_details":['Virat Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['2 year', 'Rs 4000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'12-2-2012',
					"team":['SDK Yuzvendra', 'Irfan Pathan(Partner)'],
					"company_details":['Chahal Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['3 year', 'Rs 8000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'13-2-2012',
					"team":['SDK Ramesh', 'Rohit Sharma(Partner)'],
					"company_details":['Akash Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['4 year', 'Rs 10000'],
					"add_on_plan":['400 e-Waybill', 'Rs 3000'],
					"referral_source":'Advertisement',
				},
				{
					"date":'11-2-2012',
					"team":['SDK Rahul', 'Amit Kumar(Partner)'],
					"company_details":['Abhishek Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['1 year', 'Rs 2000'],
					"add_on_plan":['50 e-Waybill', 'Rs 500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'12-2-2012',
					"team":['SDK Abhishek', 'Ayush Jain(Partner)'],
					"company_details":['Jain Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['3 year', 'Rs 8000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'13-2-2012',
					"team":['SDK Akash', 'Kajal Verma(Partner)'],
					"company_details":['Akash Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['2 year', 'Rs 5000'],
					"add_on_plan":['100 e-Waybill', 'Rs 1000'],
					"referral_source":'Advertisement',
				},
				{
					"date":'14-2-2012',
					"team":['SDK Jeevan', 'Rishabh Pant(Partner)'],
					"company_details":['Virat Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['2 year', 'Rs 4000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'12-2-2012',
					"team":['SDK Yuzvendra', 'Irfan Pathan(Partner)'],
					"company_details":['Chahal Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['3 year', 'Rs 8000'],
					"add_on_plan":['150 e-Waybill', 'Rs 1500'],
					"referral_source":'Advertisement',
				},
				{
					"date":'13-2-2012',
					"team":['SDK Ramesh', 'Rohit Sharma(Partner)'],
					"company_details":['Akash Industries', '+912822828282'],
					"status":'Not Signed Up',
					"subscription_plan":['4 year', 'Rs 10000'],
					"add_on_plan":['400 e-Waybill', 'Rs 3000'],
					"referral_source":'Advertisement',
				},
				]




export const team_data=[
		{
		"date":"12-11-2012",
		"teammate_details":['Akash Industries', '+912822828282'],
		"total_referrals":"1000",
		"subscribed_referrals":"300",
		"conversion_rate_per" :"54",
		"total_revenue":"Rs 5,00,000",
		"total_commission":"12,000",
		"sub_ordinates":"100-Partners",
		"balance":"12200",

		},
		{
		"date":"12-11-2012",
		"teammate_details":['Prakash Industries', '+912822828282'],
		"total_referrals":"10300",
		"subscribed_referrals":"3100",
		"conversion_rate_per" :"64",
		"total_revenue":"Rs 5,34,000",
		"total_commission":"12,0233",
		"balance":"12200",
		"sub_ordinates":"20-Partners",

"balance":"12200",
		},
		{
		"date":"12-11-2012",
		"teammate_details":['Nilesh Industries', '+912822828282'],
		"total_referrals":"102300",
		"subscribed_referrals":"3300",
		"conversion_rate_per" :"84",
		"total_revenue":"Rs 8,00,000",
		"total_commission":"10,000",
		"sub_ordinates":"50-Partners",

"balance":"12200",
		},
		{
		"date":"12-11-2012",
		"teammate_details":['Akash Industries', '+912822828282'],
		"total_referrals":"1000",
		"subscribed_referrals":"300",
		"conversion_rate_per" :"54",
		"total_revenue":"Rs 5,00,000",
		"total_commission":"12,000",
		"sub_ordinates":"100-Partners",
		"balance":"12200",

		},
		{
		"date":"12-11-2012",
		"teammate_details":['Akash Industries', '+912822828282'],
		"total_referrals":"1000",
		"subscribed_referrals":"300",
		"conversion_rate_per" :"54",
		"total_revenue":"Rs 5,00,000",
		"total_commission":"12,000",
		"sub_ordinates":"100-Partners",
		"balance":"12200",

		},


	]