<template>
	<div>
		 
    <ReferralTable />
	</div>
</template>

<script>
import ReferralTable from  "@/components/Referrals/ReferralTable.vue";
export default {
		name:'Referrals',
		components:{
			ReferralTable,
		},
		data(){
			return {
				selected:null,
			}
		},
		mounted(){
			this.$store.state.Auth.access=true;
		}
	}
</script>

<style scoped>
	
</style>