<template>
	<div>
		<v-row style="background-color:white;padding:10px 40px;margin-top:15px;">
        <v-col cols="3" style="display:flex;">
        <!-- <v-autocomplete
          v-model="selected"
          style="background-color:#F4F4F4;width:40%;"
          menu-props="auto"
          label="by Date"
          height="30"          
          append-inner-icon="mdi-chevron-down"
          class="complete"
          item-text="name"
          item-value="id"
          single-line
          outlined
          dense
          clearable

        >
        </v-autocomplete>
         --><div class="mx-3"></div>
        <!-- <v-autocomplete
          v-model="team_id"
          style="background-color:#F4F4F4"
          menu-props="auto"
          label="by Head"
          height="30"          
          append-inner-icon="mdi-chevron-down"
          class="complete"
          item-text="name"
          item-value="id"
         	@click:clear="Getall()"
          single-line
          @change="FilterTeamByparent"
          :items="teammates"
          outlined
          dense
          clearable

        >
        <template slot="item" slot-scope="data">
	          HTML that describe how select should render items when the select is open -->
	          <!-- {{ data.item.name }}( {{ data.item.Type }} )
	        </template>
        </v-autocomplete> -->
        </v-col>
        <v-col cols="2"  class="ml-auto">
          <v-btn  color="#363740" class="btn-client" @click="AddTeam()" >
            Add Teammate
          </v-btn>
        </v-col>
    </v-row>
		<v-tabs height="120" width="100" slider-size="0" active-class="active" class="tabs" 	>
			<v-tab class="tab"  @click="changeStatus('HEAD')"  v-if="show()==='ADMIN'" >
					<div>
						<span><v-icon color="#3ABCA7" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">HEAD<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.hd_count}}</span></p>
					</div>
			</v-tab>
			<v-tab class="tab" @click="changeStatus('SDR')" v-if="show()==='HEAD'|| show()==='ADMIN'  ">
					<div>
						<span><v-icon color="#9B51E0" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">SDR<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.sd_count}}</span></p>
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('PARTNER')" v-if="show()==='HEAD' || show()==='ADMIN' || show()==='SDR' || show()==='PARTNER' ">
					<div>
						<span><v-icon color="#F2994A" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">PARTNER<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.pt_count}}</span></p>
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('IN_EMP')">
					<div>
						<span><v-icon small color="#2F80ED" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">INTERNS & EMPLOYEES<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.ie_count}}</span></p>
					</div>
			</v-tab>
			
		</v-tabs>


			<TeamForm 
				:form_data="form_data" 
				v-if="form_data.flag" 
				@reloadTeam="getTeams"
				/>
			<PayoutForm :form_data="payout_form_data"
				v-if="payout_form_data.flag"
				
				/>
			<!--TEAM DATA TABLE -->
			<v-data-table
				class="table"
				:items="teams"
				:loading="loader"
				hide-default-footer
				style="border-bottom:1px solid black;margin-bottom:30px;"
				:headers="ref_headers"
			>
				<template v-slot:item.teammate_details="{item}">
	          <div class="text-center project-container">
	              <p> {{ item.name }} </p>
	              <p> {{ item.phone }} </p>
	          </div>
	      </template>
	      <template v-slot:item.date_joined="{item}">
			          <div class="text-center project-container">
			              <p> {{ new Date(item.date_joined).toJSON().slice(0,10).replace(/-/g,'/')  }} </p>
			              <p> </p>
			          </div>
			      </template>
	      
	      <template v-slot:item.sub_ordinates="{item}">
	          <div class="text-center project-container">
	              <p> <span v-if="item.counts.sdr_count">{{ item.counts.sdr_count }}-SDR</span>  </p>
	              <p> <span v-if="item.counts.ie_count">{{ item.counts.ie_count }}-intern/Employee</span></p>
	              <p> <span v-if="item.counts.pt_count">{{ item.counts.pt_count }}-PARTNER</span> </p>
	              <p> <span v-if="item.counts.head_count">{{ item.counts.head_count}}-HEAD</span> </p>
	          </div>
	      </template>
	      <template v-slot:item.manager="{item}">
	          <div class="text-center project-container">
	              <p> <span v-if="item.manager">{{item.manager.name}}-{{item.manager.Type}}</span>  </p>
	    
	          </div>
	      </template>
	      
         <template v-slot:item.action="{item}">
                <div class="action-button-contaner" style="display:flex;">
                 	<v-btn 
                    medium
                    style="color:white;min-width:10% !important;"
                    color="#363740" 
                    class="elevation-0"  
                    @click="AddPayout"
                    v-if="show()==='ADMIN'"
                    >
                    MAKE A PAYOUT
                </v-btn>
                <v-btn
                  color="#F2F2F2"
                  x-small
                  class="ma-1 elevation-0"
                  dark
                  @click="Edit(item.id)"
                >
                <v-icon color="black"  >mdi-pencil</v-icon>
                </v-btn>
                    
                </div>
            </template>
				
			</v-data-table>
		<div 
      class="text-center" 
      style="margin-top:10px;"
      >
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="next"
      >
      </v-pagination>
    </div>
	</div>
</template>
<script>

import {ref_data, team_data} from "@/Data/ApiDummyData.js";
import TeamForm from  "./TeamForm.vue";
import axios from "axios";
import {mapActions} from "vuex";
import PayoutForm from "@/components/Payouts/PayoutForm.vue";
import api from "@/helper-axios.js";

export default {
		name:'TeamTable',
		components:{
			TeamForm,
			PayoutForm
		},
		data(){
			return {
				form_data:{
					'flag':false,
					'type':'',
					"id":''
				},
				page:1, 
				pageCount:1,
				loader:false,
				team_id:'',
				selected:'',
				teams:[],
        payout_form_data: {
          'flag': false, 
          'type': 'add'
        },
				teammates:[],
				ref_headers:[ 
				  { text: 'DATE',align: 'center',value: 'date_joined',class : 'theader', sortable:false},
          { text: 'TEAMMATE DETAILS',align: 'center',value: 'teammate_details',class : 'theader', sortable:false},
          { text: 'TOTAL REFERRALS',align: 'center',value: 'total_referral',class : 'theader', sortable:false},
          { text: 'SUBSCRIBED REFERRALS',align: 'center',value: 'subs_ref', class : 'theader', sortable:false},
          { text: 'CONVERSION RATE %',align: 'center',value: 'get_conversion',class : 'theader', sortable:false},
          { text: 'TOTAL REVENUE',align: 'center',value: 'total_revenue',class : 'theader', sortable:false},
          { text: 'TOTAL COMISSION',align: 'center',value: 'total_commission',class : 'theader', sortable:false},
          { text: 'SUB ORDINATES',align: 'center',value: 'sub_ordinates',class : 'theader', sortable:false},
          { text: 'MANAGER',align: 'center',value: 'manager',class : 'theader', sortable:false},
           { text: 'BALANCE',align: 'center',value: 'balance',class : 'theader', sortable:false},
          { text: 'ACTIONS',align: 'center',value: 'action',class : 'theader', sortable:false},		
        ]
      }
		},
		methods:{
			...mapActions('Auth', ['getCounts', 'getAdminCounts']),
			changeStatus(status){
				this.pageCount=1
				this.selected=status;
				if(this.show()==='ADMIN'){
					console.log('admim')
					// this.selected='HEAD'
					this.getAdminTeam()
				}else{
					this.getTeams()
				}
				// this.getTeams()
			},
			show(){
				return localStorage.getItem('user_type')
			},
			next(){
				if(localStorage.getItem('user_type')==='ADMIN'){
						console.log('admim')
						// this.selected='HEAD'
						this.getAdminTeam()
				}else{
					this.getTeams()
				}
			},
      AddPayout(){
        this.payout_form_data.flag=true;
      },
			Getall(){
				this.getTeams()
			},
			Edit(id){
				// console.log(this.selected, 'in edit')
				this.form_data.id=id;
				this.form_data.type='edit'
				this.form_data.flag=true
			},
			FilterTeamByparent(){
				this.loader=true;
				console.log(this.selected)
				api.get(`${this.$store.state.Auth.staging_url}/api/team/filterbyhead/${this.team_id}/${this.selected}/`)
					.then((res)=>{
						this.teams=res.data
					})
					.catch((err)=>{
						console.log(er)
					})
					.finally(()=>{
						this.loader=false;
					})
			},
			AddTeam(){

				this.selected=this.selected;
				this.form_data.type='add';
				// console.log('add', this.form_data)
				this.form_data.flag=true;
			},
			
       
			getTeams(){
				this.teams=[]
				this.getCounts()
				this.loader=true
				const config={
			     	 headers:{
				        "Content-Type":"application/json"
				      }
				   }
				const uid=localStorage.getItem('uid');
				api.get(`${this.$store.state.Auth.staging_url}/api/team/list/${uid}/${this.selected}/${this.page}/`, config)
					.then((res)=>{
						
						this.teams=res.data.results
						this.pageCount=res.data.num
					})
					.catch(()=>{
					})
					.finally(()=>{this.loader=false;})

			},
			show(){
				if (localStorage.getItem('user_type')==='HEAD'){
					return 'HEAD'
				}
				if(localStorage.getItem('user_type')==='SDR'){
					return 'SDR'
				}
				if(localStorage.getItem('user_type')==='PARTNER'){
					return  "PARTNER"
				}
				if(localStorage.getItem('user_type')==='IN_EMP'){
					return  "IN_EMP"
				}
				if(localStorage.getItem('user_type')==='ADMIN'){
					return  "ADMIN"
				}
			},
			getTeammates(){
	      const uid=localStorage.getItem('uid')
	    
	      api.get(`${this.$store.state.Auth.staging_url}/api/team/filter/${uid}/`)  
	        .then((res)=>{
	          this.teammates=res.data
	          
	        })
	        .catch((err)=>{
	         
	        })
    	},
			getAdminTeam(){
				this.loader=true
				console.log('executed')
				this.teams=[]
        const token = localStorage.getItem('access')
				const config={
          headers:{
            Accept: "application/json",
            "Content-Type":"application/json",
            "Authorization" : `jwt ${token}`
          }
				}
				axios.get(`${this.$store.state.Auth.staging_url}/api/team/admin/list/${this.page}/${this.selected}/`, config)	
					.then((res)=>{
						console.log(res.data, 'ndjndjn')
						this.teams=res.data
					})
					.catch(()=>{
						// console.log(err)
					})
					.finally(()=>{
						this.getAdminCounts()
						this.loader=false;}
						)

			}
		},
		computed:{
			
		},
		mounted(){
			console.log(this.show())
			if (this.show()=='HEAD'){
					this.selected='SDR'
				}else if(this.show()=='SDR'){
					this.selected='PARTNER'
				}else if  (this.show()=='PARTNER'){
					this.selected='IN_EMP'
				}

				console.log(this.selected)
			if(this.show()==='ADMIN'){
				console.log('admim')
				this.selected='HEAD'
				this.getAdminTeam()
			}else{
				this.getTeams()
			}
			// this.getTeammates()
		}
	}
</script>

<style>
	.active{
		background-color:#F2F2F2;
		border-bottom:None !important;
	}
	.tabs{
		border-top:1px solid black;
		border-bottom:0.5px solid grey;
	}
	.tab{
			border-right:1px solid black;
			min-width:160px !important;
			border-bottom:1px solid black;
	}
	.tab__title{
		text-transform:capitalize ;
		color:black;
		font-weight:400;
		padding-top:20px;

	}
	.theader{
		font-size:18px;
	  height:70px;
	  text-emphasis: wrap;
	  border-top:None !important;
	  background-color: #F5F6FA;
	}
	.project-container p {
    font-size: 12px;
    font-weight:450;
    margin: 0;
    padding:0;

    }

.project-container  {
    padding:10px;
     font-size: 15px;
    color: gray;
}
.table td{
    border-bottom: none !important;
    border-right: 2px solid #F2F2F2;
    font-size: 13px !important;
    
}
.complete{
	 width:80% !important;
	 border:1px solid #F4F4F4;
	 background-color:#F4F4F4;
	 max-height:40px  !important;
	 outline:none;
	}
	.btn-client{
	  color:white !important;
	  text-align:right;
	  width:200px;
	  padding:20px;
	  text-transform:capitalize;
	}

</style>