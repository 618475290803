<template>
	<div>
		<v-row style="background-color:white;padding:10px 40px;margin-top:15px;">
        <v-col cols=3>
<!--         
        <v-autocomplete
          menu-props="auto"
          label="Teammate"
          height="30"          
          append-inner-icon="mdi-chevron-down"
          class="complete"
          item-text="name"
          item-value="id"
          v-model="team_id"
          single-line
          outlined
          dense
          @click:clear="Getall()"
          @change="SearchByTeammate(team_id)"
          :items="teammates"
          style="background-color:#F4F4F4"
          clearable
        >
	        <template slot="item" slot-scope="data"> -->
	          <!-- HTML that describe how select should render items when the select is open -->
	          <!-- {{ data.item.name }}( {{ data.item.Type }} )
	        </template>
        </v-autocomplete> -->
        </v-col>
        <v-col cols="2"  class="ml-auto">
          <v-btn  color="#363740" class="btn-client" @click="AddReferral()" >
            Add Referral
          </v-btn>
        </v-col>
    </v-row>
		<v-tabs height="120" width="100" slider-size="0" active-class="active" class="tabs" >
			<v-tab class="tab"  @click="changeStatus('NOT SIGNED UP')" >
					<div>
						<span><v-icon color="#3ABCA7" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">Not Signed up <br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.nsu_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab" @click="changeStatus('SIGNED UP')">
					<div>
						<span><v-icon color="#9B51E0" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">Signed up
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{$store.state.Auth.su_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('NOT SUBSCRIBED')">
					<div>
						<span><v-icon color="#F2994A" small style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">Trial Over & Not Subscribed
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.nsub_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('REGULAR SUBSCRIPTION')">
					<div>
						<span><v-icon small color="#2F80ED" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">Regular Subscription
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.regular_sub_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('E INVOICE SUBSCRIPTION')">
					<div>
						<span><v-icon small color="#EB5757" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">E-Invoice Subscription
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.e_invoice_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('LOAN')">
					<div>
						<span><v-icon small color="#BB6BD9" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">Loan
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.loan_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('E-WAYBILL')">
					<div>
						<span><v-icon small color="#828282" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">E-Waybill
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.e_waybill_count}}</span></p>
						
					</div>
			</v-tab>
			<v-tab class="tab"  @click="changeStatus('GST FILING')">
					<div>
						<span><v-icon small color="#F2C94C" style="padding:10px ;margin-top:20px;background-color:#F2F1FA;height:30px;width:30px">mdi-checkbox-blank-circle</v-icon></span>
						<p class="tab__title">GST Filing
						<br><br><span style="color:#2F80ED;font-size:22px;font-weight:700;">{{this.$store.state.Auth.gst_count}}</span></p>
						
					</div>
			</v-tab>
			
		</v-tabs>


			<ReferralForm 
				:form_data="form_data" 
				v-if="form_data.flag"  
				@reloadReferral="getReferrals()"
				/>
			
			<!--REFERRALS DATA TABLE -->
			<v-data-table
				class="table"
				:items="this.referrals"
				hide-default-footer
				:headers="ref_headers"
				style="border-bottom:1px solid black;margin-bottom:30px;"
			>				<template v-slot:item.date="{item}">
			          <div class="text-center project-container">
			              <p> {{ new Date(item.date).toJSON().slice(0,10).replace(/-/g,'/')  }} </p>
			              <p> </p>
			          </div>
			      </template>
					<template v-slot:item.team="{item}">
			          <div class="text-center project-container">
			              <p> {{item.team.name.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}} - {{ item.team.Type }}   </p>
			              <p> </p>
			          </div>
					</template>
					<template v-slot:item.company="{item}">
			          <div class="text-center project-container">
			              <p> <span v-if="item.company">{{ item.company_name}} </span> <span v-else>{{item.company_name}}</span></p>
			              <p> {{ item.mobile }} </p>
			          </div>
					</template>
					<template v-slot:item.add_on_plan="{item}">
			          <div class="text-center project-container">
			              <p> {{ item.add_on_plan}} </p>
			              <p>  </p>
			          </div>
					</template>
					<template v-slot:item.subscription_plan="{item}">
			          <div class="text-center project-container">
			                <p> <span v-html="item.subscription_plan" v-if="item.subscription_plan" > </span> <span v-else>--</span></p>
			           </div>
					
					</template>
					<template v-slot:item.manager="{item}">
						<div class="text-center project-container">
							<p> <span v-if="item.manager">{{item.manager.name}}-{{item.manager.Type}}</span>  </p>
					
						</div>
					</template>
						<template v-slot:item.status="{item}">
		            <v-chip
		                
		                :style="{ 'background-color' : getColor(item.status)}"
		                style="font-weight : bold; font-size : 14px;opacity:0.5;box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);"
		                dark
		                :text-color="getDarkColor(item.status)"
		                >
		                {{ item.status }}
		            </v-chip>
		        </template>	
		         <template v-slot:item.action="{item}">
		                <div class="action-button-contaner">
		                 	<v-btn 
		                  large  
		                  style="color:white;min-width:30%;"
		                  color="#363740" 
		                  class="elevation-0 ma-1 cl-view"  
		                  @click="ViewReferral(item.id)"
		                  >
		                  VIEW
		                </v-btn>
		               
		                <v-btn
		                    color="#F2F2F2"
		                    small
		                    class="elevation-0 ma-1"
		                    dark
		                >
		                <v-icon color="black" class>mdi-dots-horizontal</v-icon>
		                </v-btn>
		                      
		                </div>
		            </template>
			</v-data-table>
				<div 
	      class="text-center" 
	      style="margin-top:20px;"
	      >
	      <v-pagination
	        v-model="page"
	        :length="pageCount"
	        :total-visible="7"
	        @input="next"
	      >
	      </v-pagination>
	    </div>
	</div>
</template>
<script>

import ReferralForm from  "./ReferralForm.vue";
import api from "@/helper-axios.js";
import {mapActions} from "vuex";

export default {
		name:'ReferralTable',
		components:{
			ReferralForm,
			
		},
		data(){
			return {
				form_data:{
					'flag':false,
					'type':'',
					'disabled':false,
					'id':null,
				},
				teammates:[],
				page:1,
				pageCount:1,
				team_id:"",
				selected:'NOT SIGNED UP',
				referrals:[],
				ref_headers:[ { text: 'DATE',align: 'center',value: 'date',class : 'theader', sortable:false},
          { text: 'TEAM',align: 'center',value: 'team',class : 'theader', sortable:false},
          { text: 'COMPANY DETAILS',align: 'center',value: 'company',class : 'theader', sortable:false},
          { text: 'REFFERRED FOR',align: 'center',value: 'referred_for',class : 'theader', sortable:false},
          { text: 'STATUS',align: 'center',value: 'status', class : 'theader', sortable:false},
          { text: 'SUBSCRIPTION PLAN',align: 'center',value: 'subs_amount',class : 'theader', sortable:false},
          { text: 'ADD-ON PLAN',align: 'center',value: 'add_on_plan',class : 'theader', sortable:false},
          { text: 'REFERRAL SOURCE',align: 'center',value: 'source',class : 'theader', sortable:false},
          { text: 'MANAGER',align: 'center',value: 'manager',class : 'theader', sortable:false},
          { text: 'ACTIONS',align: 'center',value: 'action',class : 'theader', sortable:false},		
        ]
      }
		},
		methods:{
			...mapActions('Auth', ['getCounts', 'getAdminCounts']),
			changeStatus(status){
				this.page=1

				this.selected=status;
				if(localStorage.getItem('user_type')==='ADMIN'){
				console.log('admim')
				// this.selected='HEAD'
				this.getReferrals()
			}else{
				this.getReferrals()
			}},
			next(){
				if(localStorage.getItem('user_type')==='ADMIN'){
						console.log('admim')
						// this.selected='HEAD'
						this.getAdminReferrals()
				}else{
					this.getReferrals()
				}
			},
			Getall(){
				this.changeStatus(this.selected)
			},
			SearchByTeammate(id){
				this.loader=true
				api.get(`${this.$store.state.Auth.staging_url}/api/referral/search/${this.team_id}/${this.selected}/`)
					.then((res)=>{
						this.referrals=res.data
					})
					.catch((err)=>{
						console.log(err)
					})
					.finally(()=>{
						this.loader=false;
					})
			},
			getTeammates(){
	      const uid=localStorage.getItem('uid')
	    
	      api.get(`${this.$store.state.Auth.staging_url}/api/team/filter/${uid}/`)  
	        .then((res)=>{
	          this.teammates=res.data
			  
	          // console.log(this.items, 'items')
	          // console.log( this.managers)
	        })
	        .catch((err)=>{
	          // console.log(err)
	        })
    	},
			getColor(status) {
            // console.log(status);
            if (status === 'SIGNED UP') return "#c693f5"
            if(status === 'NOT SIGNED UP') return '#91ffec' 
            if(status === 'NOT SUBSCRIBED') return '#fac698'
            if(status === 'REGULAR SUBSCRIPTION') return "#77aef7"
			if(status === 'E INVOICE SUBSCRIPTION') return "#f59f9f"
			if(status === 'LOAN') return '#daa8ed'
			if(status === 'GST FILING') return '#F2C94C'
        },
	      getDarkColor(status){

	      console.log(status);
	            if (status === 'NOT SUBSCRIBED') return '#ff7803'
	            if(status === 'NOT SIGNED UP') return '#377a6f' 
	            if(status === 'SIGNED UP') return '#7e10e3'
	            if(status === 'REGULAR SUBSCRIPTION') return "#2167c4"
				if(status === 'E INVOICE SUBSCRIPTION') return "#EB5757"
				if(status === 'LOAN') return '#BB6BD9'
				if(status === 'GST FILING') return '#F2C94C'
	        },
			getReferrals(){
				this.referrals=[]
				this.getCounts()
				// console.log(this.selected, 'status')
				const uid=localStorage.getItem('uid')
				api.get(`${this.$store.state.Auth.staging_url}/api/referral/list/${this.page}/${uid}/${this.selected}/`)
					.then((res)=>{
						console.log(res.data)
						this.referrals=res.data.results
						// if (res.data.status.name.includes(this.selected)){
							this.referrals.forEach((item)=>{
								const statuses = item.status.map(item=>item.name)
								if(statuses.includes(this.selected)){
									item.status = this.selected
								}
								// item.status = this.selected;
							})
						// }
						this.pageCount=res.data.num
					})

			},
			getAdminReferrals(){
				this.referrals=[]
				this.loader=true
				// this.getCounts()
				this.getAdminCounts()
				// console.log(this.selected, 'status')
				// const uid=localStorage.getItem('uid')
				api.get(`${this.$store.state.Auth.staging_url}/api/referral/admin/list/${this.page}/${this.selected}/`)
					.then((res)=>{
						// console.log(res.data)
						this.referrals=res.data.results
						this.pageCount=res.data.num
					}).catch(()=>{
						this.loader=false
					})
			},
	
			AddReferral(){
				this.form_data.type='add';
				this.form_data.flag=true;
			},

   		EditReferral(id){
   			this.form_data.type="edit";
   			this.form_data.id=id;
   			this.form_data.flag=true;
   		}	,	
   		ViewReferral(id){
   			this.form_data.type="view";
   			this.form_data.id=id;
   			this.form_data.disabled=true;
   			this.form_data.flag=true;
   			
   		}
		},
		mounted(){
			
			
			if(localStorage.getItem('user_type')==='ADMIN'){
				console.log('admim')
				// this.selected='HEAD'
				// this.getAdminReferrals()
				// this.getAdminCounts()
				
			}else{
				this.getReferrals()
				this.getCounts()
			}
			// this.getTeammates()
		}
	}
</script>

<style>
	.active{
		background-color:#F2F2F2;
		border-bottom:None !important;
	}
	.tabs{
		border-top:1px solid black;
		border-bottom:0.5px solid grey;
	}
	.tab{
			border-right:1px solid black;
			min-width:160px !important;
			border-bottom:1px solid black;
	}
	.tab__title{
		text-transform:capitalize ;
		color:black;
		font-weight:400;
		padding-top:10px;

	}
	.theader{
		font-size:18px;
	  height:70px;
	  text-emphasis: wrap;
	  background-color: #F5F6FA;
	}
	.project-container p {
    font-size: 13px;
    font-weight:460;
    margin: 0;

}

.project-container  {
    padding:10px;
     font-size: 15px;
    color: gray;
    whiteSpace:'nowrap'
}
.table td{
    border-bottom: none !important;
    border-right: 2px solid #F2F2F2;
    font-size: 13px !important;
    
}
.complete{
	 width:220px !important;
	 border:1px solid #F4F4F4;
	 background-color:#F4F4F4;
	 max-height:40px  !important;
	 outline:none;
	}
	.complete:hover{
		background-color:#F4F4F4;
	 
	}	
	.btn-client{
	  color:white !important;
	  text-align:right;
	  width:200px;
	  padding:20px;
	  text-transform:capitalize;
	}

</style>