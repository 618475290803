<template>
  <div>
    <v-row
      style="background-color: white; padding: 10px 40px; margin-top: 15px"
    >
      <v-col cols="3" style="display: flex">
        <!-- <v-autocomplete
          v-model="selected"
          style="background-color:#F4F4F4"
          menu-props="auto"
          label="by Date"
          height="30"          
          append-inner-icon="mdi-chevron-down"
          class="complete"
          item-text="name"
          item-value="id"
          single-line
          outlined
          dense
          clearable
        >
        </v-autocomplete>
        <div class="mx-3"></div>
        <v-autocomplete
          v-model="selected"
          style="background-color:#F4F4F4"
          menu-props="auto"
          label="by Teammate"
          height="30"          
          append-inner-icon="mdi-chevron-down"
          class="complete"
          item-text="name"
          item-value="id"
          single-line
          outlined
          dense
          clearable
        >
        </v-autocomplete> -->
      </v-col>
      <v-col cols="2" class="ml-auto" v-if="show() === 'ADMIN'">
        <v-btn color="#363740" class="btn-client" @click="AddReferral()">
          Add Payout
        </v-btn>
      </v-col>
    </v-row>
    <v-tabs
      height="120"
      width="100"
      slider-size="0"
      active-class="active"
      class="tabs"
    >
      <v-tab class="tab" @click="changeStatus('success')">
        <div>
          <span
            ><v-icon
              color="#3ABCA7"
              small
              style="
                padding: 10px;
                margin-top: 20px;
                background-color: #f2f1fa;
                height: 30px;
                width: 30px;
              "
              >mdi-checkbox-blank-circle</v-icon
            ></span
          >
          <p class="tab__title">
            Success <br /><br /><span
              style="color: #2f80ed; font-size: 22px; font-weight: 700"
              >{{ $store.state.Auth.success_count }}</span
            >
          </p>
        </div>
      </v-tab>
      <v-tab
        class="tab"
        @click="changeStatus('cancelled')"
        v-if="show() === 'ADMIN'"
      >
        <div>
          <span
            ><v-icon
              color="red"
              small
              style="
                padding: 10px;
                margin-top: 20px;
                background-color: #f2f1fa;
                height: 30px;
                width: 30px;
              "
              >mdi-checkbox-blank-circle</v-icon
            ></span
          >
          <p class="tab__title">
            Cancelled <br /><br /><span
              style="color: #2f80ed; font-size: 22px; font-weight: 700"
              >{{ $store.state.Auth.cancel_count }}</span
            >
          </p>
        </div>
      </v-tab>
    </v-tabs>

    <PayoutForm
      :form_data="form_data"
      v-if="form_data.flag"
      @reloadPayout="Reload()"
    />
    <!--REFERRALS DATA TABLE -->
    <v-data-table
      class="table"
      hide-default-footer
      style="border-bottom: 1px solid black; margin-bottom: 30px"
      :items="payouts"
      :headers="ref_headers"
    >
      <template v-slot:item.team="{ item }">
        <div class="text-center project-container">
          <p>{{ item.team.phone }}</p>
          <p>{{ item.team.name }}</p>
        </div>
      </template>

      <template v-slot:item.type="{ item }">
        <div class="text-center project-container">
          <p>{{ item.team.role }}</p>
        </div>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="text-center project-container">
          <p>
            {{ new Date(item.date).toJSON().slice(0, 10).replace(/-/g, "/") }}
          </p>
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <div
          style="display: flex; margin: 0px !important; padding: 0px !important"
        >
          <CancelPayout
            v-if="show() === 'ADMIN'"
            class="elevation-0"
            @reloadPayout="Reload()"
            :id="item.id"
          />
        </div>
      </template>
    </v-data-table>
    <div class="text-center" style="margin-top: 20px">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="7"
        @input="next"
      >
      </v-pagination>
    </div>
  </div>
</template>
<script>
import { ref_data, team_data } from "@/Data/ApiDummyData.js";
import PayoutForm from "./PayoutForm.vue";
import CancelPayout from "./CancelPayout.vue";
import { mapActions } from "vuex";
import api from "@/helper-axios.js";

export default {
  name: "PayoutTable",
  components: {
    PayoutForm,
    CancelPayout,
  },
  data() {
    return {
      form_data: {
        flag: false,
        type: "",
        id: "",
      },
      selected: "success",
      loader: "",
      page: 1,
      pageCount: 1,
      payouts: [],
      ref_headers: [
        {
          text: "TEAMMATE DETAILS",
          align: "center",
          value: "team",
          class: "theader",
          sortable: false,
        },
        {
          text: "TEAM TYPE",
          align: "center",
          value: "type",
          class: "theader",
          sortable: false,
        },
        {
          text: "DATE",
          align: "center",
          value: "date",
          class: "theader",
          sortable: false,
        },
        {
          text: "AMOUNT",
          align: "center",
          value: "amount",
          class: "theader",
          sortable: false,
        },
        {
          text: "REMARK",
          align: "center",
          value: "remark",
          class: "theader",
          sortable: false,
        },
        {
          text: "ACTIONS",
          align: "center",
          value: "action",
          class: "theader",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("Auth", ["getCounts", "getAdminCounts"]),
    changeStatus(status) {
      this.selected = status;
      console.log("status", status);
      if (this.show() === "ADMIN") {
        console.log("admim");
        // this.selected='success'
        this.getAdminPayouts();
        this.getCounts();
      } else {
        this.getPayouts();
        this.getCounts();
      }
    },
    next() {
      if (localStorage.getItem("user_type") === "ADMIN") {
        console.log("admim");
        // this.selected='HEAD'
        this.getAdminPayouts();
      } else {
        this.getPayouts();
      }
    },
    Reload() {
      this.changeStatus(this.selected);
    },
    EditPayout(id) {
      this.form_data.type = "edit";
      this.form_data.id = id;
      this.form_data.flag = true;
    },

    show() {
      if (localStorage.getItem("user_type") === "HEAD") {
        return "HEAD";
      }
      if (localStorage.getItem("user_type") === "SDR") {
        return "SDR";
      }
      if (localStorage.getItem("user_type") === "Partner") {
        return "Partner";
      }
      if (localStorage.getItem("user_type") === "in_emp") {
        return "in_emp";
      }
      if (localStorage.getItem("user_type") === "ADMIN") {
        return "ADMIN";
      }
    },
    getPayouts() {
      this.payouts = [];
      console.log(this.selected, "next time ");

      this.loader = true;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const uid = localStorage.getItem("uid");
      api
        .get(
          `${this.$store.state.Auth.staging_url}/api/payout/mypayouts/${uid}/${this.page}/`,
          config
        )
        .then((res) => {
          console.log(res.data);
          this.payouts = res.data.results;
          this.pageCount = res.data.num;
        })
        .catch(() => {
          console.log(err);
        })
        .finally(() => {
          this.getCounts();
          this.loader = false;
        });
    },

    getAdminPayouts() {
      this.payouts = [];
      console.log(this.selected, "next time ");

      this.loader = true;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      api
        .get(
          `${this.$store.state.Auth.staging_url}/api/payout/admin/list/${this.page}/${this.selected}/`,
          config
        )
        .then((res) => {
          console.log(res.data);
          this.payouts = res.data.results;
          this.pageCount = res.data.num;
        })
        .catch(() => {
          console.log(err);
        })
        .finally(() => {
          console.log("admincalled");
          this.loader = false;
        });
    },

    AddReferral() {
      this.form_data.type = "add";
      this.form_data.flag = true;
    },
  },
  mounted() {
    // console.log(ref_data)
    if (this.show() === "ADMIN") {
      console.log("admim");
      this.selected = "success";
      this.getAdminPayouts();
      this.getAdminCounts();
    } else {
      this.getPayouts();
      this.getCounts();
    }
  },
};
</script>

<style>
.active {
  background-color: #f2f2f2;
  border-bottom: None !important;
}
.tabs {
  border-top: 1px solid black;
  border-bottom: 0.5px solid grey;
}
.tab {
  border-right: 1px solid black;
  min-width: 160px !important;
  border-bottom: 1px solid black;
}
.tab__title {
  text-transform: capitalize;
  color: black;
  font-weight: 400;
  padding-top: 20px;
}
.theader {
  font-size: 18px;
  height: 70px;
  text-emphasis: wrap;
  background-color: #f5f6fa;
}
.project-container p {
  font-size: 13px;
  font-weight: 460;
  margin: 0;
}

.project-container {
  padding: 10px;
  font-size: 15px;
  color: gray;
}
.table td {
  border-bottom: none !important;
  border-right: 2px solid #f2f2f2;
  font-size: 13px !important;
}
.complete {
  width: 220px !important;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  max-height: 40px !important;
  outline: none;
}
.complete:hover {
  background-color: #f4f4f4;
}
.btn-client {
  color: white !important;
  text-align: right;
  width: 200px;
  padding: 20px;
  text-transform: capitalize;
}
</style>
