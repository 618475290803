<template>
	<div>
		<TeamTable />
	</div>
</template>

<script>
import TeamTable from "@/components/Team/TeamTable.vue";

export default {
	name:'Team',
	components:{
		TeamTable,
	},
	methods:{},
	mounted(){
		this.$store.state.Auth.access=true;
	}   
}
</script>


<style scoped>
	
</style>