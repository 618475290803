<template>
  <div>
    <v-dialog v-model="form_data.flag" max-width="550px">
      <div v-if="loader" class="text-center" style="min-height: 200px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card v-if="!loader">
        <v-card-title
          class="headline darken-1"
          style="background-color: #363740; color: white"
        >
          {{ Heading }} Referral
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            @click.prevent="CloseForm()"
            large
            style="cursor"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-form style="padding: 20px; background-color: #f4f4f4">
          <v-row>
            <v-col cols="12" md="6">
              <label>Mobile No <span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="mobile"
                :disabled="form_data.disabled"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label>Company Name <span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="company"
                :disabled="form_data.disabled"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label>Company Email </label>
              <v-text-field
                outlined
                v-model="email"
                :disabled="form_data.disabled"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <label>Referral Source <span style="color: red">*</span></label>
              <v-select
                v-model="source"
                :items="items"
                :disabled="form_data.disabled"
                outlined
                style="background-color: #fff; height: 40px"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <label>Referred For <span style="color: red">*</span></label>
              <v-select
                v-model="referred_for"
                :items="referred_for_items"
                :disabled="form_data.disabled"
                outlined
                item-value="value"
                item-text="name"
                multiple
                color="#fff"
                style="background-color: #fff; height:40px;"
                dense
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <label>Referred From(Optional)</label>
              <v-select
                v-model="referred_from"
                :items="team_list_items"
                :disabled="form_data.disabled"
                outlined
                item-value="id"
                item-text="name"
                color="#fff"
                style="background-color: #fff; height:40px;"
                dense
              ></v-select>
            </v-col>
            
          </v-row>
        </v-form>
        <div style="min-height: 50px; background-color: #f4f4f4"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
            "
            color="#363740"
            large
            v-if="this.form_data.type == 'add'"
            @click="AddReferral()"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
            "
            color="#363740"
            large
            loader="btnloader"
            v-if="this.form_data.type == 'edit'"
            @click="EditReferral()"
            type="submit"
          >
            Edit
          </v-btn>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
            "
            color="#363740"
            large
            v-if="this.form_data.type == 'view'"
            @click="CloseForm"
            type="submit"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapActions } from "vuex";

import api from  "@/helper-axios.js";

export default {
  name: "ReferralForm",
  props: ["form_data"],
  data() {
    return {
      items: ["Advertisement", "Word of Mouth"],
      statuses: ["Subscribed", "Not_Subscribed", "Signed_Up", "Not_Signed_Up"],
      // referred_for_items:[{'name':'Regular Subscription', 'value':'REGULAR SUBSCRIPTION'}, {'name':"E-invoice Subscription", 'value':"E INVOICE SUBSCRIPTION"}, {'name':"Loan", 'value':"LOAN"}, {"name":"E-Way-Bill", 'value':"E-WAYBILL"}, {"name" : "GST Filing", "value" : "GST FILING"}],
      referred_for_items:['REGULAR SUBSCRIPTION',"E INVOICE SUBSCRIPTION", "E-Way-Bill", "GST FILING", "LOAN"],
      source: null,
      referred_for:[],
      referred_from:"",
      team_list_items:[''],
      status: null,
      mobile: null,
      company: null,
      email: "",
      loader: false,
      btnloader: false,
    };
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    validateMobile(phone) {
      // console.log(phone)
      const pattern = /^([0]|\+91)?[6789]\d{9}$/;
      if (phone.toString().match(pattern)) {
        // console.log("Matched");
        return true;
      } else {
        return false;
      }
    },
     validateEmail(mail)
      {
        const pattern=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (mail.match(pattern)){
          return true
        }else{
          return false
        }
      },    

    EditReferral() {
      if (this.mobile && this.company && this.source && this.referred_for) {
        this.loader = true;
        this.btnloader = true;
        if (!this.validateMobile(this.mobile)) {
          this.loader = false;
          this.showSnack({
            text: "Enter a valid Phone Number",
            color: "error",
            timeout: 3500,
          });
          return false;
        }
        if(this.email){
          if (!this.validateEmail(this.email)) {
            this.loader = false;
            this.showSnack({
              text: "Enter a valid Email id",
              color: "error",
              timeout: 3500,
            });
            return false;
          }
        }
        const data = {
          company: this.company,
          mobile: this.mobile,
          company_name: this.company,
          source: this.source,
          email: this.email,
          referred_from: this.referred_from
        };
        // console.log(data)
        api
          .put(
            `${this.$store.state.Auth.staging_url}/api/referral/edit/${this.form_data.id}/`,
            data
          )
          .then((res) => {
            // console.log(res.data)
            this.showSnack({
              text: "Teammate was Edited",
              color: "success",
              timeout: 3500,
            });
            this.$emit("reloadReferral");
            setTimeout(() => {
              // this.CloseForm()
            }, 1000);
          })
          .catch((err) => {
            // console.log(err)
          })
          .finally(() => {
            this.loader = false;
            this.btnloader = true;
          });
      } else {
        this.showSnack({
          text: "Please fill all required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },
    getTeamList(){
      const uid = localStorage.getItem('uid')
      api.get(`${this.$store.state.Auth.staging_url}/api/team/list/${uid}/`)
        .then((res)=>{
          console.log(res.data)
          res.data.forEach((item)=>{
            this.team_list_items.push(item)
          })
          // this.team_list_items = res.data

        })
    },
    AddReferral() {
      if (this.mobile && this.company && this.source && this.referred_for ) {
        if (!this.validateMobile(this.mobile)) {
          this.showSnack({
            text: "Enter a valid Phone Number",
            color: "error",
            timeout: 3500,
          });

          return false;
        }
        if(this.email){
          if (!this.validateEmail(this.email)) {
            this.loader = false;
            this.showSnack({
              text: "Enter a valid Email id",
              color: "error",
              timeout: 3500,
            });
            return false;
          }
        }
        const data = {
          company: this.company,
          mobile: this.mobile,
          referred_for: this.referred_for,
          source: this.source,
          email: this.email
        };
        if(this.referred_from){
          data['team_id'] = this.referred_from
        }else{
          data['team_id'] = localStorage.getItem("uid")
        }

        console.log(data)
        api
          .post(`${this.$store.state.Auth.staging_url}/api/referral/add/`, data)
          .then((res) => {
            // console.log(res.data)
            if (res.data.status) {
              this.showSnack({
                text: `${res.data.message}`,
                color: "success",
                timeout: 3500,
              });
              this.$emit("reloadReferral");
              setTimeout(() => {
                this.CloseForm();
              }, 1000);
            } else {
              this.showSnack({
                text: `${res.data.message}`,
                color: "error",
                timeout: 3500,
              });
            }
          })
          .catch((err) => {
            // console.log(err)
          });
      } else {
        this.showSnack({
          text: "Please fill al required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },
    CloseForm() {
      this.form_data.flag = false;
      (this.form_data.id = ""),
        (this.form_data.type = ""),
        (this.form_data.disabled = false);
    },
  },

  computed: {
    Heading() {
      if (this.form_data.type === "edit") {
        return "Edit";
      } else if (this.form_data.type === "view") {
        return "View";
      } else {
        return "Add";
      }
    },
  },

  mounted() {
    this.getTeamList()
    if (this.form_data.type == "edit" || this.form_data.type == "view") {
      this.loader = true;
      // console.log(this.form_data.id)
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      api
        .get(
          `${this.$store.state.Auth.staging_url}/api/referral/get/${this.form_data.id}/`,
          config
        )
        .then((res) => {
          console.log(res.data);
          this.mobile = res.data.mobile;
          this.company = res.data.company_name;
          this.status = res.data.status;
          this.source = res.data.source;
          this.referred_for_items = res.data.referred_for.split(",")
          this.email = res.data.email
          console.log(this.referred_for_items)
})
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          this.loader = false;
        });
    }
  },
};
</script>

<style scope></style>
