<template>
  <div>
    <v-dialog
      v-model="form_data.flag"
      max-width="650px"
      height="100%"
      style="min-height: 70vh !important"
    >
      <div v-if="loader" class="text-center" style="min-height: 200px">
        <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card v-if="!loader" style="min-height: 70vh !important">
        <v-card-title
          class="headline darken-1"
          style="background-color: #363740; color: white"
        >
          {{ Heading }} Teammate<span> </span>
          <v-spacer></v-spacer>
          <v-icon
            text
            color="white"
            @click.prevent="CloseForm()"
            large
            style="cursor"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-form style="padding: 20px; background-color: #f4f4f4">
          <v-row>
            <v-col cols="6">
              <label>Teammate Type<span style="color: red">*</span></label>

              <v-select
                :items="arr"
                outlined
                v-model="Type"
                style="background-color: #fff; height: 40px"
                dense
                @change="UpdateCommission"
              ></v-select>
            </v-col>
            <v-col cols="6" v-if="is_admin">
              <label>Manager</label>

              <v-select
                :items="managers"
                outlined
                v-model="parent_id"
                item-text="name"
                item-value="phone"
                style="background-color: #fff; height: 40px"
                dense
              >
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.name }}( {{ data.item.Type }} )
                </template>
              </v-select>
            </v-col>
            <v-col cols="6">
              <label>Name<span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="team_name"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <label>Mobile No<span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="phone"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Email<span style="color: red">*</span></label>
              <v-text-field
                outlined
                v-model="email"
                style="background-color: #fff; height: 40px"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <label>Address<span style="color: red">*</span></label>
              <v-textarea
                outlined
                v-model="address"
                auto-grow
                style="background-color: #fff; max-height: 120px !important"
                dense
                max-height="90px"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <div class="mt-9">
            <h3 class="mb-2">Commission %</h3>
            <v-row>
              <v-col cols="4">
                Plan name
              </v-col>
              <v-col cols="3">
                <label>First Time<span style="color: red">*</span></label>
                
              </v-col>
              <v-col cols="4">
                <label class="pl-12">Recurring <span style="color: red">*</span></label>
                
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-2" cols="4">
                <span >Regular Subscription</span>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="regular_subscription_first"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="regular_subscription_recurring"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              
                
            </v-row>
            <v-row>
              <v-col cols="4" class="mt-2">
                E-Invoice Subscription
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="e_invoice_subscription_first"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="e_invoice_subscription_recurring"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              
                
            </v-row>
            <v-row>
              <v-col cols="4" class="mt-2">
                E-Waybill
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="e_waybill_first"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="e_waybill_recurring"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              
                
            </v-row>
            <v-row>
              <v-col cols="4" class="mt-2">
                GST Filing
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="gst_filing_first"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="gst_filing_recurring"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              
                
            </v-row>
            <v-row>
              <v-col cols="4" class="mt-2">
                Loan
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="loan_first"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  v-model="loan_recurring"
                  type="number"
                  :disabled="disableCommission || is_partner"
                  style="background-color: #fff; height: 40px"
                  dense
                >
                </v-text-field>
                
              </v-col>
              
                
            </v-row>

          </div>
        </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
              text-transform: capitalize;
            "
            color="#363740"
            large
            type="submit"
            v-if="form_data.type == 'add'"
            @click="AddTeam"
          >
            Add
          </v-btn>
          <v-btn
            style="
              width: 150px;
              padding: 10px;
              color: white;
              margin-right: 20px;
              margin-bottom: 15px;
              text-transform: capitalize;
            "
            color="#363740"
            large
            v-if="form_data.type == 'edit'"
            type="submit"
            @click="EditTeam"
          >
            Edit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import api from "@/helper-axios.js";

export default {
  name: "TeamForm",

  data() {
    return {
      team_name: "",
      items: ["Advertisement", "Word of Mouth"],
      Type: "",
      email: "",
      phone: "",
      loader: false,
      parent_id: null,
      address: "",
      managers: [],
      disableCommission:false,
      loan_first: "",
      loan_recurring: "",
      e_waybill_first: "",
      e_waybill_recurring: "",
      gst_filing_first: "",
      gst_filing_recurring: "",
      regular_subscription_first: "",
      regular_subscription_recurring: "",
      e_invoice_subscription_first: "",
      e_invoice_subscription_recurring: "",
      
    };
  },
  props: ["form_data"],

  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    validateMobile(phone) {
      console.log(phone);
      const pattern = /^([0]|\+91)?[6789]\d{9}$/;
      if (phone.toString().match(pattern)) {
        console.log("Matched");
        return true;
      } else {
        return false;
      }
    },
    validateEmail(mail) {
      const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (mail.match(pattern)) {
        return true;
      } else {
        return false;
      }
    },
    CloseForm() {
      this.form_data.flag = false;
    },
    UpdateCommission(){
      if(this.Type==='PARTNER'){
        this.disableCommission = true;
        this.loan_first = 0.75;
        this.loan_recurring = 0.00;
        this.e_waybill_first = 30;
        this.e_waybill_recurring = 20;
        this.gst_filing_first = 30;
        this.gst_filing_recurring = 20;
        this.e_invoice_subscription_first = 60;
        this.e_invoice_subscription_recurring = 25;
        this.regular_subscription_recurring = 25;
        this.regular_subscription_first = 60;
      }else{
        this.disableCommission = false;
        this.loan_first = 0.0;
        this.loan_recurring = 0.0;
        this.e_waybill_first = 0.0;
        this.e_waybill_recurring = 0.0;
        this.gst_filing_first = 0.0;
        this.gst_filing_recurring = 0.0;
        this.e_invoice_subscription_first = 0.0;
        this.e_invoice_subscription_recurring = 0.0;
        this.regular_subscription_recurring = 0.0;
        this.regular_subscription_first = 0.0;
        
      }
    },

    getManagers() {
      const data = {
        uid: localStorage.getItem("uid"),
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log(data);
      this.managers = [];
      const uid = localStorage.getItem("uid");
      api
        .get(
          `${this.$store.state.Auth.staging_url}/api/team/filter/${uid}/`,
          data,
          config
        )
        .then((res) => {
          res.data.forEach((item) => {
            const data = {
              name: item.name,
              phone: item.phone,
              type: item.Type,
            };
            this.managers.push(data);
          });

          this.managers = res.data;
          console.log(this.managers, "maange");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    EditTeam() {
      if (
        this.phone &&
        this.email &&
        this.address &&
        this.Type &&
        this.team_name 
      ) {
        this.loader = true;
        if (!this.validateMobile(this.phone)) {
          this.showSnack({
            text: "Enter a valid Phone Number",
            color: "error",
            timeout: 3500,
          });
          this.loader = false;
          return false;
        }

        if (!this.validateEmail(this.email)) {
          this.showSnack({
            text: "Enter a valid Email id ",
            color: "error",
            timeout: 3500,
          });
          this.loader = false;
          return false;
        }
        const data = {
          phone: this.phone,
          name: this.team_name,
          address: this.address,
          email: this.email,
          Type: this.Type,
          loan_first: this.loan_first,
          loan_recurring: this.loan_recurring,
          e_waybill_first: this.e_waybill_first,
          e_waybill_recurring: this.e_waybill_recurring,
          gst_filing_first: this.gst_filing_first, 
          gst_filing_recurring: this.gst_filing_recurring,
          regular_subscription_first: this.regular_subscription_first, 
          regular_subscription_recurring: this.regular_subscription_recurring,
          e_invoice_subscription_first: this.e_invoice_subscription_first,
          e_invoice_subscription_recurring: this.e_invoice_subscription_recurring 
        };
        console.log(data);
        api
          .put(
            `${this.$store.state.Auth.staging_url}/api/team/edit/${this.form_data.id}/`,
            data
          )
          .then((res) => {
            console.log(res.data);
            this.showSnack({
              text: "Teammate was Edited",
              color: "success",
              timeout: 3500,
            });
            this.$emit("reloadTeam");
            setTimeout(() => {
              this.CloseForm();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        this.showSnack({
          text: "Please fill all required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },

    AddTeam() {
      console.log(this.phone,
        this.email,
        this.address,
        this.Type,
        this.team_name,
        this.loan_first,
        this.loan_recurring,
        this.e_waybill_first,
        this.e_waybill_recurring,
        this.gst_filing_first, 
        this.gst_filing_recurring,
        this.regular_subscription_first, 
        this.regular_subscription_recurring,
        this.e_invoice_subscription_first,
        this.e_invoice_subscription_recurring )
      if (
        this.phone &&
        this.email &&
        this.address &&
        this.Type &&
        this.team_name 
       
        
      ) {
        if (this.Type === "INTERN/EMPLOYEE") {
          this.Type = "IN_EMP";
        }
        if (!this.validateMobile(this.phone)) {
          this.showSnack({
            text: "Enter a valid Phone Number",
            color: "error",
            timeout: 3500,
          });

          return false;
        }

        if (!this.validateEmail(this.email)) {
          this.showSnack({
            text: "Enter a valid Email id ",
            color: "error",
            timeout: 3500,
          });

          return false;
        }
        if (this.parent_id === null || this.parent_id === undefined) {
          this.parent_id = localStorage.getItem("uid");
        }
        console.log(this.parent_id, "eieie");
        const data = {
          phone: this.phone,
          name: this.team_name,
          address: this.address,
          email: this.email,
          Type: this.Type,
          parent_id: this.parent_id,
          loan_first: this.loan_first,
          loan_recurring: this.loan_recurring,
          e_waybill_first: this.e_waybill_first,
          e_waybill_recurring: this.e_waybill_recurring,
          gst_filing_first: this.gst_filing_first, 
          gst_filing_recurring: this.gst_filing_recurring,
          regular_subscription_first: this.regular_subscription_first, 
          regular_subscription_recurring: this.regular_subscription_recurring,
          e_invoice_subscription_first: this.e_invoice_subscription_first,
          e_invoice_subscription_recurring: this.e_invoice_subscription_recurring 
        };
        console.log(data);
        api
          .post(`${this.$store.state.Auth.staging_url}/api/team/add/`, data)
          .then((res) => {
            console.log(res.data);
            if (res.data.status) {
              this.showSnack({
                text: "Teammate was Added",
                color: "success",
                timeout: 3500,
              });
              this.$emit("reloadTeam");
              setTimeout(() => {
                this.CloseForm();
              }, 1000);
            } else {
              this.showSnack({
                text: `${res.data.message}`,
                color: "error",
                timeout: 3500,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.showSnack({
          text: "Please fill al required fields",
          color: "error",
          timeout: 3500,
        });
      }
    },
  },
  mounted() {
    console.log("add", this.form_data);
    // this.getManagers()
    // console.log(localStorage.getItem('uid'))
    console.log(this.team_name, this.team_types, this.phone);
    if (this.form_data.type == "edit") {
      console.log(this.form_data);
      this.loader = true;
      console.log(this.form_data.id);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      api
        .get(
          `${this.$store.state.Auth.staging_url}/api/team/edit/${this.form_data.id}/`,
          config
        )
        .then((res) => {
          console.log(res.data, "ejnejn");
          this.phone = res.data["phone"];
          this.team_name = res.data["name"];
          this.address = res.data["address"];
          this.loan_first = res.data['loan_first'];
          this.loan_recurring = res.data['loan_recurring'];
          this.e_waybill_first = res.data['e_waybill_first'];
          this.e_waybill_recurring = res.data['e_waybill_recurring'];
          this.gst_filing_first = res.data['gst_filing_first']; 
          this.gst_filing_recurring = res.data['gst_filing_recurring'];
          this.regular_subscription_first = res.data['regular_subscription_first'];
          this.regular_subscription_recurring = res.data['regular_subscription_recurring'];
          this.e_invoice_subscription_first = res.data['e_invoice_subscription_first'];
          this.e_invoice_subscription_recurring = res.data['e_invoice_subscription_recurring'];
          this.email = res.data["email"];
          res.data.commission_plan_data.forEach((item)=>{
            if(item.plan_type==='LOAN'){
              this.loan_first = item.first_time;
              this.loan_recurring = item.recurring;
            }
            else if(item.plan_type==='REGULAR SUBSCRIPTION'){
              this.regular_subscription_first = item.first_time;
              this.regular_subscription_recurring = item.recurring;
            }
            else if(item.plan_type==='E INVOICE SUBSCRIPTION'){
              this.e_invoice_subscription_first = item.first_time;
              this.e_invoice_subscription_recurring = item.recurring;
            }
            else if(item.plan_type==='E-WAYBILL'){
              this.e_waybill_first = item.first_time;
              this.e_waybill_recurring = item.recurring;
            }
            else if(item.plan_type==='GST FILING'){
              this.gst_filing_first = item.first_time;
              this.gst_filing_recurring = item.recurring;
            }
          })
          if(res.data['role'] === "IN_EMP"){
            this.Type = 'INTERN/EMPLOYEE'
          }else{
            this.Type = res.data["role"];  
          }


          setTimeout(() => {
            this.parent_id = parseInt(res.data.parent_id);
            console.log(this.parent_id, "id", this.managers);
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loader = false;
        });
    }
  },
  computed: {
    is_partner(){
      if(this.Type==='PARTNER'){
        this.disableCommission=true;
      }
    },
    is_admin() {
      return localStorage.getItem("user_type") === "ADMIN";
    },
    arr() {
      if (localStorage.getItem("user_type") === "HEAD") {
        console.log("head");
        return ["SDR", "PARTNER", "INTERN/EMPLOYEE"];
      } else if (localStorage.getItem("user_type") === "ADMIN") {
        return ["HEAD", "SDR", "PARTNER", "INTERN/EMPLOYEE"];
      } else if (localStorage.getItem("user_type") === "SDR") {
        return ["PARTNER", "INTERN/EMPLOYEE"];
      } else if (localStorage.getItem("user_type") === "PARTNER") {
        return ["INTERN/EMPLOYEE"];
      }
    },
    Heading() {
      if (this.form_data.type === "edit") {
        return "Edit";
      } else {
        return "Add";
      }
    },
  },
};
</script>

<style scope>
label {
  font-size: 14px;
  color: grey;
}
</style>